@import "../../styles/variables.scss";

.footer {
  background-color: rgb(12, 12, 12);
  color: white;
  text-align: center;
  padding: 1.3rem 0;

  .container {
    @include container;
    flex-direction: column;
  }

  .links {
    @include content-flex("center");
    list-style: none;
    margin-top: 0.5rem;
    text-transform: capitalize;
    font-size: $font-size-small;

    li {
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .socialMedia {
    margin: 1rem 0 2rem;
    @include content-flex("center");

    a {
      margin-right: 1rem;
      @include content-flex("center");
      $size: 2.3rem;
      height: $size;
      width: $size;
      border-radius: $size * 0.5;

      background: $btn-gradient;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .icon {
    font-size: 1rem;
  }

  p {
    margin-top: 1rem;
    font-size: $font-size-small;
  }
}

@media (max-width: 600px) {
  .footer {
    .links {
      display: block;
      li {
        margin-right: 0;
      }
    }
  }
}
