@import "../../styles/variables.scss";

.header {
  position: fixed;
  width: 100%;
  z-index: 10000;
  color: white;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeUp {
  from {
    opacity: 1;
  }
  to {
    transform: translate3d(0, -100%, 0);
    opacity: 0;
  }
}

.header2 {
  @extend .header;
  box-shadow: $box-shadow2;
  background-color: White;
  color: $text-color;
  animation: fadeIn 0.6s ease-in-out;
}

.container {
  @include container;
}

.contacts {
  animation: fadeIn 0.6s ease-in-out;

  background: $btn-gradient;

  color: $text-color;
  padding: 0.7rem 0;

  a {
    margin-right: 0.5rem;
    align-content: center;
    font-size: $font-size-small;
    transition: 0.2s;

    @include content-flex("");

    &:last-child {
      margin-right: 0;
    }
  }

  .links {
    @include content-flex("");

    .mail {
      margin-right: 1rem;
    }

    &:last-child {
      a {
        margin-right: 1.5rem;
        &:last-child {
          margin-right: 0;
        }
      }

      .icon {
        font-size: 1.15rem;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .icon {
    margin-right: 0.5rem;
    font-size: $font-size;
  }

  .container {
    @include content-flex("sb");
  }
}

.navbar {
  padding: 1.05rem 0;

  .container {
    @include content-flex("sb");

    ul {
      @include content-flex("");
      list-style: none;
      text-transform: uppercase;
      font-size: $font-size-small;
      font-weight: bold;

      li {
        margin-right: 2rem;
        cursor: pointer;

        &:hover {
          color: $text-color;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.mobileMenu {
  position: absolute;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  background-color: #fff;
  transition: 0.2s linear;
  list-style: none;

  width: 80%;
  margin: 0 auto;

  top: 100%;
  left: 0;
  right: 0;

  li {
    padding: 1rem 1.6rem;
    color: $text-color;

    &:first-child {
      border-radius: $border-radius $border-radius 0 0;
    }

    &:last-child {
      border-radius: 0 0 $border-radius $border-radius;
    }

    &:hover {
      background-color: $background-color;
    }
  }
}

.menuBtn {
  display: none;
  font-size: 1rem;
}

@media (max-width: 800px) {
  .contacts {
    display: none;
  }
  .menuBtn {
    display: block;
  }

  .navbar {
    .container {
      .menu {
        display: none;
      }
    }
  }
}
