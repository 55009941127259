// @import "../../styles/variables";

.mainContainer {
  min-height: 100vh;
  overflow: hidden;

  display: grid;
  grid-template-rows: auto 1fr auto;

  main {
    position: relative;
  }

  .header {
    position: fixed;

    z-index: 1000;
    top: 0;
    right: 0;
    left: 0;
  }
}
