@import "../../../styles/variables.scss";

.contacts {
  margin-top: 70px;

  .title {
    @include title;
    text-align: start;

    hr {
      margin-left: 0;
    }
  }

  .container {
    @include container;
  }

  .socialMedia {
    margin: 1rem 0;

    a {
      margin-right: 1.2rem;
      font-size: 1.4rem;

      .icon {
        color: $primary-color;
      }
    }
  }

  .grid {
    display: grid;
    grid-template-columns: 1.15fr 0.85fr;

    .column {
      &:last-child {
        h3 {
          margin: 1.8rem 0;
        }

        .row {
          @include content-flex("");
          margin: 1.4rem 0;

          .icon {
            margin-right: 1rem;
            font-size: 1.2rem;
            color: $primary-color;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .contacts {
    margin: 0;
    .container {
      width: 100%;
      .grid {
        display: block;
        .column {
          &:first-child {
            display: none;
          }

          &:last-child {
            padding: 70px 0;

            .container {
              @include container;
            }
            background: url(../../../public/contact3.jpg) no-repeat center
              top/cover;
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
}
