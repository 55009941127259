@import "variables";

.container {
  @include container;
}

.extra {
  color: white;
  margin-top: 80px;
  position: relative;
  background: $btn-gradient;
  padding: 4rem 0;

  .overlay {
    text-align: center;
    line-height: 2.4em;
  }
}

.contactTeam {
  background-image: $btn-gradient;
  padding: 1rem 0;
  color: white;

  p {
    font-size: $font-size-small;
    margin-top: 0.5rem;
  }

  .container {
    @include content-flex("sb");

    .btn {
      @include btn;
      background: $tertiary-color;
      text-transform: uppercase;
      font-size: $font-size-small;

      .background {
        background: white;
      }

      &:hover {
        color: $text-color;
        box-shadow: $box-shadow;
      }
    }
  }
}

@media (max-width: 1160px) {
  .contactTeam {
    .container {
      text-align: center;
      flex-direction: column;

      p {
        margin: 0.5rem 0;
      }
    }
  }
}
