@import "../../../styles/variables.scss";

.carousel {
  position: relative;
  height: 100vh;
}

.container {
  @include container;
  width: 70%;
  z-index: 2;
}

$delay: 1s;
$duration: 1.3s;
$time-function: ease-out;
$fill-mode: forwards;

.slide {
  position: absolute;
  background-color: $background-color;
  height: 100%;
  width: 100%;
  opacity: 0;
  // transition: all 0.5s linear;

  .overlay {
    position: absolute;
    @include content-flex("center");

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    background-color: rgba($color: #000000, $alpha: 0.45);
    color: white;

    h1 {
      font-size: 2.5rem;
      text-transform: uppercase;
      opacity: 0;
    }
    p {
      opacity: 0;
      margin: 1rem 0;
    }

    .btn {
      @include btn;
      margin: 0.5rem auto;
      opacity: 0;
      text-transform: uppercase;
    }
  }

  &:first-child {
    background: url(../../../../public/interior.jpg) no-repeat center top/cover;
  }

  &:last-child {
    background: url(../../../../public/carousel7.jpg) no-repeat center bottom/cover;
  }
}

.current {
  opacity: 1;

  h1 {
    animation: fadeRight $duration $time-function $fill-mode 0.3s;
  }

  p {
    animation: fadeUp $duration $time-function $fill-mode 1.3s;
  }

  .btn {
    animation: fadeUp $duration $time-function $fill-mode 1.8s;
  }
}

@keyframes fadeRight {
  from {
    opacity: 0;
    transform: translate3d(-12%, 0, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translate3d(0, 110%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@media (max-width: 800px) {
  .container {
    width: 80%;
  }
}

@media (max-width: 400px) {
  .slide {
    .overlay {
      h1 {
        font-size: 2rem;
      }
    }
  }
}
