@import "../../../styles/variables.scss";

.about {
  margin-top: 70px;

  .title {
    @include title;
  }

  .container {
    @include container;
  }

  .description {
    text-align: center;
    color: $text-color-secondary;
    font-size: $font-size-small;
  }

  .grid {
    margin-top: 60px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .column {
      &:first-child {
        .image {
          border-radius: $border-radius;
          box-shadow: $box-shadow2;
          margin: 1rem;
        }
      }

      &:last-child {
        .header {
          font-size: 1.3rem;
        }

        ul {
          list-style: none;
          margin-top: 1rem;
          font-size: $font-size-small;

          li {
            margin: 0.5rem 0;

            .wrapper {
              @include content-flex("sb");
            }

            .icon {
              color: $primary-color;
              margin-right: 1rem;
              font-size: 1.4rem;
            }
          }
        }

        .wrapper2 {
          @include content-flex("sb");
          text-align: center;
          margin-top: 2rem;
          font-size: $font-size-small;

          .iconWrapper {
            @include content-flex("center");
            background: $btn-gradient;
            color: white;
            font-size: 1.5rem;

            $size: 50px;
            height: $size;
            width: $size;
            border-radius: $size * 0.5;
            margin: 0.4rem auto;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .about {
    .grid {
      display: block;

      .column {
        &:first-child {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .about {
    .block {
      display: none;
    }
  }
}
