@import "../../../styles/variables.scss";

.services {
  margin-top: 70px;
  position: relative;
  overflow: hidden;

  .title {
    @include title;
  }

  .container {
    @include container;
  }

  .btns {
    @include content-flex("center");
    margin-top: 40px;

    .wrapper {
      @include content-flex("");
      border-radius: $border-radius;
      box-shadow: $box-shadow;
    }

    p {
      background-color: white;
      padding: 0.65rem 2rem;
      cursor: pointer;

      &:first-child {
        border-radius: $border-radius 0 0 $border-radius;
      }
      &:last-child {
        border-radius: 0 $border-radius $border-radius 0;
      }
    }
  }

  .row {
    $width: 340px;
    margin-top: 70px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax($width, 1fr));
    justify-content: space-between;
    gap: 3rem 2rem;

    .card {
      .imageWrapper {
        position: relative;
        height: 190px;
        margin-bottom: 0.58rem;

        .image {
          border-radius: $border-radius2 $border-radius2 0 0;
          width: 100%;
        }
      }

      p {
        font-size: $font-size-small;
        margin: 0.58rem 0;
        color: $text-color-secondary;
      }
    }

    .card2 {
      border: 1px solid $background-color;
      border-radius: $border-radius2;
      padding: 1rem 1.3rem;
      background-color: white;
      z-index: 1;

      .iconWrapper {
        .icon {
          @include content-flex("center");
          transition: 0.4s;
          font-size: 2.6rem;
          margin: 1rem 0;
          color: $primary-color;
        }
      }

      p {
        margin: 0.5rem 0;
        font-size: $font-size-small;
        color: $text-color-secondary;
      }

      &:hover {
        .iconWrapper {
          .icon {
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
          }
        }
      }
    }
  }

  .section {
    padding: 70px 0;
    margin-top: 70px;
    background-color: $background-color-light;

    .wrapper {
      display: grid;
      grid-template-columns: auto auto;
      align-items: center;

      .info {
        border-left: 1px solid $background-color-dark;
        padding: 1rem 0;
        margin-left: 3rem;
        p {
          margin-left: 3rem;
          font-size: $font-size-small;
          color: $text-color-secondary;
        }
      }
    }

    .title {
      text-align: start;
      hr {
        margin-left: 0;
      }
    }

    .row {
      background-color: white;
      box-shadow: $box-shadow2;
      padding: 2rem;
      gap: 3rem 1.5rem;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;

    h3 {
      margin: 3rem 2rem 1rem 0;

      span {
        color: $primary-color;
      }
    }

    .column {
      position: relative;
      .imageWrapper {
        position: absolute;
        transform: translateY(-70%);
      }
    }
  }
}

@media (max-width: 800px) {
  .services {
    .section {
      .wrapper {
        display: block;

        .info {
          border: none;
          margin-left: 0;
          p {
            margin-left: 0;
          }
        }
      }
    }
  }
}
@media (max-width: 600px) {
  .services {
    .grid {
      display: none;
    }

    .row {
      $width: 300px;
      display: block;

      .card,
      .card2 {
        margin-bottom: 2rem;
        border: none;
      }
    }

    .section {
      .row {
        background-color: transparent;
        padding: 0;
      }
    }
  }
}
