@import "styles/variables";
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap");

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  position: relative;

  padding: 0;
  margin: 0;

  color: $text-color;
  // background-color: $background-color;

  font-family: $font-family;
  font-size: $font-size;
  font-weight: $font-weight;
  // line-height: $line-height;

  overflow-y: auto;
  scroll-behavior: smooth;

  @include scrollbar-style;
  &::-webkit-scrollbar {
    width: 13px;
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid $background-color;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  line-height: $line-height;
}

* {
  box-sizing: border-box;
  // transition: 0.2s;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  max-height: 100%;
}
