// @font-face {
//   font-family: "Roboto";
//   src: url("../fonts/roboto/Roboto-Regular.ttf");
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Roboto";
//   src: url("../fonts/roboto/Roboto-Bold.ttf");
//   font-weight: bold;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Roboto";
//   src: url("../fonts/roboto/Roboto-Italic.ttf");
//   font-weight: normal;
//   font-style: italic;
// }

// ======================= colors ==============================
$text-color: #2c3145;
$text-color-secondary: #70757a;
// $text-color-secondary: #2c3145;

$primary-color: #045de9;
// $primary-color: #007bff;
$secondary-color: #343a40;
$tertiary-color: rgb(32, 32, 32);

$background-color: #edf2f6;
// $background-color: #f1f3f4;
$background-color-dark: #cecece;
$background-color-light: #fafafa;

$box-shadow: rgba(59, 59, 128, 0.2) 0px 7px 29px 0px;
// $box-shadow2: rgba(59, 59, 128, 0.2) 0px 2px 8px 0px;
$box-shadow2: 0rem 0.3125rem 0.3125rem 0rem rgb(82 63 105 / 5%);

$btn-shadow: 0 8px 30px 0px rgba(0, 123, 255, 0.8);
$btn-shadow2: 0px 2px 8px 0px rgba(0, 123, 255, 0.8);

// ======================= fonts and sizes  ==============================
$font-size: 1rem;
$font-size-small: 0.875rem;
$font-weight: 400;

$border-radius: 1.25rem;
$border-radius2: 0.25rem;

$head-text-size: 30px;
$head-text-weight: 500;
$header-margin-top: 70px;
$sub-header-weight: bolder;
$sub-header-size: 34px;

$font-family: "Rubik", sans-serif;
$line-height: 1.58em;

$btn-grad-first: #09c6f9;
$btn-grad-second: #007bff;
$btn-grad-third: #045de9;

$btn-gradient: linear-gradient(
  -90deg,
  $btn-grad-first 0%,
  $btn-grad-second 56%,
  $btn-grad-third 100%
);

$btn-gradient2: linear-gradient(
  90deg,
  $btn-grad-first 0%,
  $btn-grad-second 56%,
  $btn-grad-third 100%
);

//========== flex styles ===========
@mixin content-flex($flex) {
  display: flex;
  align-items: center;

  @if $flex == "center" {
    justify-content: center;
  } @else if $flex == "sb" {
    justify-content: space-between;
  } @else if $flex == "se" {
    justify-content: space-evenly;
  } @else if $flex == "sa" {
    justify-content: space-around;
  }
}

@mixin container {
  margin: auto;
  width: 85%;
}

@keyframes fadeColorLeft {
  0% {
    opacity: 1;
    border-radius: $border-radius;
    width: 100%;
  }
  5% {
    opacity: 1;
    border-radius: $border-radius 0 0 $border-radius;
    width: 100%;
  }
  100% {
    opacity: 0;
    border-radius: $border-radius;
    width: 0;
  }
}

@keyframes fadeColorRight {
  0% {
    opacity: 0;
    width: 0;
    // border-radius: $border-radius 0 0 $border-radius;
  }
  5% {
    opacity: 1;
    width: 5%;
    border-radius: $border-radius 0 0 $border-radius;
  }
  99% {
    opacity: 1;
    width: 90%;
    border-radius: $border-radius 0 0 $border-radius;
  }
  100% {
    opacity: 1;
    width: 100%;
    border-radius: $border-radius;
  }
}

@mixin btn {
  @include content-flex("center");
  position: relative;
  text-align: center;
  color: white;
  width: 12rem;
  height: 2.7rem;
  background: $btn-gradient;
  border-radius: $border-radius;
  transition: 0.24s;
  cursor: pointer;

  span {
    z-index: 1000;
  }

  .background {
    position: absolute;
    background: $btn-gradient2;
    top: 0;
    left: 0;
    height: 100%;
    animation: fadeColorLeft 0.3s ease-out forwards;
  }

  &:hover {
    box-shadow: $btn-shadow2;
    .background {
      animation: fadeColorRight 0.3s ease-out forwards;
    }
  }
}

@mixin title {
  text-align: center;
  margin: 0 0 30px;
  position: relative;
  text-transform: uppercase;

  p {
    color: $primary-color;
    font-weight: bold;
  }

  h1 {
    margin: 0 0 0.6rem;
    // font-size: 2.2rem;
  }

  hr {
    border: none;
    height: 3px;
    background-color: $primary-color;
    width: 40px;
    margin: 0 auto;
  }
}

@mixin scrollbar-style {
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    // background: $white-color;
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $background-color-dark;
    border: 1px solid transparent;
    // border: 3px solid $white-color;
    box-shadow: $box-shadow;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #737b8b;
  }
}

@mixin overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

:export {
  primaryColor: $primary-color;
  fontFamily: $font-family;
}
